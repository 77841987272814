<button
    *ngIf="!href"
    [attr.id]="id"
    type="button"
    [linkId]="linkId"
    finderLinkId
    [analyticsTracking] = "analyticsTracking"
    class="finder-button {{ theme }}"
    [ngClass]="{
        'anchor': anchor,
        'selected': selected,
        'full-width': fullWidth
    }"
    [attr.title]="title"
    [attr.aria-pressed]="selected"
    [attr.aria-controls]="ariaControls"
    [attr.aria-expanded]="ariaExpanded"
    [attr.aria-haspopup]="ariaHasPopup"
    [attr.aria-label]="ariaLabel"
    [attr.disabled]="disabled ? '' : null"
    [attr.tabindex]="tabIndex">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</button>

<a
    *ngIf="href"
    [attr.id]="id"
    type="button"
    [linkId]="linkId"
    finderLinkId
    finderLocale
    class="finder-button {{ theme }}"
    [ngClass]="{
        'anchor': anchor,
        'fit-text': fitText,
        'full-width': fullWidth,
        'interstitialModalThirdParty triggerInterstitialModal': externalLink
    }"
    [attr.title]="title"
    [attr.disabled]="disabled ? '' : null"
    [attr.aria-label]="ariaLabel"
    [attr.href]="href"
    [attr.target]="target"
    [attr.tabindex]="tabIndex">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>

<ng-template #contentTpl>
    <finder-font-icon *ngIf="icon" [name]="icon" size="m"></finder-font-icon>
    <ng-content></ng-content>
</ng-template>
