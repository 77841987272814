import { Component, OnInit, Input, ElementRef, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import simpleParallax from 'simple-parallax-js';

import { IPicture } from '@finder/shared/components/picture/picture.interface';
import { FeatureDetectionService } from '@finder/shared/services/feature-detection/feature-detection.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ViewportDetection } from '@finder/shared/services/viewport-detection/viewport-detection.service';
import getDefaultImageSVGDataUri from '@finder/shared/utils/string/getDefaultImageSVGDataUri';

const COMPONENT_NAME = 'FINDER-PICTURE --';

/**
 * Creates a picture component that accepts a desktop and mobile image.
 * The component can inherit object-fit sizing if "fit" is present in the class.
 *
 * <finder-picture [picture]="Picture" class?="fit"></finder-picture>
 */
@Component({
    selector: 'finder-picture',
    templateUrl: './picture.component.html',
    styleUrls: ['./picture.component.scss']
})
export class PictureComponent implements OnInit, OnDestroy, AfterViewInit {

    /**
     * @member {IPicture} picture The picture object that contains the desktop and mobile image
     */
    @Input() picture: IPicture;
    @Input() lazyLoadOffset = 400; // pixels
    @Input() lazyLoad = true; // lazy load is enabled by default
    @Input() loadingType;

    public media: any = {};
    public objectFitSupport = true;
    public polyfillUrl: string;
    public defaultImage: SafeUrl;
    public rootMargin: string;
    public loaded = false;
    public loading = true;
    private isDesktop: boolean;
    private imageUrl: string;
    private defaultSVGDataUri: string;
    private warningMessage = 'A desktop or mobile picture is required to display an image';
    private parallaxImage;

    constructor(
        private logger: LoggerService,
        private hostElement: ElementRef,
        private featureDetection: FeatureDetectionService,
        private sanitizer: DomSanitizer,
        private viewportDetection: ViewportDetection,
    ) {}

    ngOnDestroy(): void {
        // Clean up the parallax image when leaving the page
        if (this.parallaxImage) {
            this.parallaxImage.destroy();
        }
    }

    @HostListener('transitionend', ['$event'])
    onTransitionEnd($event) {
        // After the image is loaded and the fade in
        // effect finishes remove the css transition property
        // to no be in conflic with other transition properties
        // applied to the image.
        this.loading = false;
    }

    public getElementRef(): Element {
        return this.hostElement.nativeElement;
    }

    ngOnInit() {
        if (this.lazyLoad) {
            this.isDesktop = this.viewportDetection.isDesktop();
            this.rootMargin = `${this.lazyLoadOffset}px 0px`;
            this.imageUrl = this.isDesktop ? this.picture?.desktop : this.picture?.mobile;
            this.defaultSVGDataUri = getDefaultImageSVGDataUri(this.imageUrl);
            this.defaultImage = this.sanitizer.bypassSecurityTrustUrl(this.defaultSVGDataUri);
        } else {
            this.loading = false;
            this.loaded = true;
            this.init();
        }

        this.loadingType = this.loadingType ? this.loadingType : 'lazy';
    }

    ngAfterViewInit(): void {
        this.checkForParallax();
    }

    init() {
        /**
         * Check to see if object-fit is supported.
         * If fit class is applied to the component apply polyfill
         *
         * Based on this PR
         * https://github.disney.com/wdpro-development/wdpro-finder/pull/1358/files
         */
        if (!this.featureDetection.hasObjectFit() && this.getElementRef().classList.contains('fit')) {
            this.objectFitSupport = false;
            this.polyfillUrl = `url(${this.picture.desktop})`;
        }

        // Check for media if desktop and mobile are present
        if (this.picture.desktop && this.picture.mobile && !this.picture.media) {
            this.logger.warn(`${COMPONENT_NAME} A media query must be present if a desktop and mobile image is specified`);
        }

        // Make sure we have either a desktop or mobile picture
        if (!this.picture.desktop && !this.picture.mobile) {
            this.logger.warn(`${COMPONENT_NAME} ${this.warningMessage}`);
        }

        this.media = this.picture;
    }

    onLoad(event) {
        if (event.target.src === this.defaultSVGDataUri) {
            return;
        }
        this.loaded = true;
    }

    onIntersectionObserver(value) {
        if (!value.isIntersecting) {
            return;
        }
        const {desktop, mobile} = this.picture;
        // Make sure we have either a desktop or mobile picture
        if (!desktop && !mobile) {
            this.logger.warn(`${COMPONENT_NAME} ${this.warningMessage}`);
        }
        // preload the image
        const image = new Image();
        // if we are in desktop and we only have a mobile size image
        // make sure we load it
        image.src = this.imageUrl || desktop || mobile;

        image.onload = () => {
            this.init();
        };

        return image;
    }

    private checkForParallax() {
        if (!this.picture.parallax) {
            return;
        }

        this.parallaxImage =
                new simpleParallax(this.getElementRef().querySelector('.picture-img'), { scale: Number(this.picture.parallaxScale) });
    }
}
