<picture
    *ngIf="objectFitSupport; else polyfill"
    [rootMargin]="rootMargin"
    intersectOnce="true"
    [intersectionEnabled]="lazyLoad"
    (finderIntersection)="onIntersectionObserver($event)"
    >
    <source *ngIf="media.mobile && media.desktop" [srcset]="media.mobile" [media]="media.media" />
    <!-- set this to the desktop picture so it works in IE11 -->
    <img class="picture-img" *ngIf="picture"
        height="100%"
        width="100%"
        [ngClass]="{'loading': loading, 'loaded': loaded}"
        [attr.alt]="picture.alt || ''"
        [attr.aria-label]="picture.alt !== '' ? picture.alt : null"
        [src]="media.desktop || media.mobile || defaultImage"
        [attr.loading]="loadingType"
        (load)="onLoad($event)"
    />
</picture>
<ng-template #polyfill>
    <!-- object-fit polyfill for IE11 -->
    <div [style.backgroundImage]="polyfillUrl" class="polyfill">
        <img class="picture-img" *ngIf="picture"
            [src]="picture.desktop"
            [attr.alt]="picture.alt || ''"
            [attr.aria-label]="picture.alt !== '' ? picture.alt : null"
        />
    </div>
</ng-template>
