import { Component, Input} from '@angular/core';

@Component({
    selector: 'finder-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
    @Input() id: string;
    @Input() disabled: boolean;
    @Input() href: string;
    @Input() linkId: string;
    @Input() theme: string;
    @Input() fitText: boolean;
    @Input() selected: boolean;
    @Input() fullWidth: boolean;
    @Input() externalLink: boolean;
    @Input() anchor = false;
    @Input() title: string;
    @Input() target = '_self';
    @Input() analyticsTracking = true;
    @Input() ariaControls: string;
    @Input() ariaExpanded: boolean;
    @Input() ariaHasPopup: boolean;
    @Input() ariaLabel: string;
    @Input() tabIndex = 0;
    @Input() icon: string;

    constructor() { }
}
