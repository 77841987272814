import { SeoOptionsInterface } from '@wdpr/ra-angular-seo-metadata';
import { environment } from 'environments/environment';

export const CONFIG_CONSTANTS = {
    apiUrlKey: 'webApiUrl',
    cacheBuster: 'cacheBuster',
    cdnListKey: 'cdnList',
    cdnPathKey: 'cdnPath',
    defaultVersionKey: 'webApiDefaultVersion',
    developmentEnv: 'development',
    disneyId: 'disneyId',
    envKey: 'environment',
    eveTimeoutKey: 'eveTimeout',
    oneIdKey: 'oneId',
    passholderLinkRoute: 'affiliations.passholderLinkRoute',
    prodEnvValue: 'prod',
    siteIdKey: 'siteId',
    svgPathKey: 'svgPath',
    tradeSessionExtendInterval: 'travelAgentSessionExtendInterval',
    tradeSessionUrl: 'travelAgentSessionUrl',
    watcher: 'watcher',
};

const explorerServicePath = 'explorer-service';
const keyringServicePath = 'keyring-service';
const searchServicePath = 'search-service';
const profileServicePath = 'profile-service';
const contentInspectionServicePath = 'content-inspection-service';

export const LOGIN_URL = '/login';

// Keep the API_CONSTANTS sorted.
export const API_CONSTANTS = {
    authEndpoint: 'authz/public',
    authStatusEndpoint: 'authentication/status',
    calendarData: `${explorerServicePath}/calendar`,
    campaignEndpoint: `${explorerServicePath}/campaign-entity-simple`,
    createPersonalScheduleItemEndpoint: `${keyringServicePath}/personal-schedule-item`,
    currencyExchangeRateEndpoint: 'convert-currency/exchange-rate',
    detailsEndpoint: `${explorerServicePath}/details-entity-simple`,
    detailsMealPeriodScheduleEndpoint: `${explorerServicePath}/meal-periods-schedule`,
    detailsScheduleEndpoint: `${explorerServicePath}/details-entity-schedule`,
    diningAvailabilityCalendarDaysEndpoint: `${keyringServicePath}/dining-availability-calendar-days`,
    diningAvailabilityCalendarDaysApiEndpoint: `dining-availability-calendar-days`,
    diningAvailabilityEndpoint: `${explorerServicePath}/dining-availability`,
    diningAvailabilityEndpointList: `${explorerServicePath}/dining-availability-list`,
    friendsAndFamilyEndpoint: `${keyringServicePath}/friends-and-family`,
    jwtCookieName: 'pep_jwt_token',
    listAncestorEntitiesEndpoint: `${explorerServicePath}/list-ancestor-entities`,
    livePersonEndpoint: `${profileServicePath}/live-person`,
    listServiceEndpoint: 'list-service/details-slugs',
    mediaListEndpoint: 'media-list',
    monthlyAvailabilityEndpoint: `${explorerServicePath}/monthly-availability`,
    personalizedContentEndpoint: 'recommendation-service/personalized-content',
    reservationConflictingItemsEndpoint: 'reservation-service/conflicting-items',
    resourceBundleEndpoint: 'language-bundles',
    searchQueryEndpoint: `${searchServicePath}/search`,
    searchSelectionHistoryEndpoint: `${searchServicePath}/search-history`,
    eveFormConfigEndpoint: `${contentInspectionServicePath}/form-config`,
    eveInspectFormEndpoint: `${contentInspectionServicePath}/inspect`,
    soloBaseEndpoint: 'solo',
    webApiVersionEndpoint: 'version',
    themesOverridesEndpoint: 'themes-overrides',
};

export const SCHEDULE_CONSTANTS = {
    scheduleTimesThreshold: 3,
};

export const SCHEDULE_TYPES = {
    operating: 'Operating',
    closedSchedule: 'Closed',
    refurbishment: 'Refurbishment',
    noPerformance: 'No Performance',
    performanceTime: 'Performance Time',
    extraMagicHours: 'Extra Magic Hours',
    specialTicketedEvent: 'Special Ticketed Event',
    extraMagicHourMagicMorning: 'Extra Magic Hour and Magic Morning',
    earlyEntry: 'Early Entry',
    extendedEvening: 'Extended Evening'
};

export const MEDIA_ENGINE_CONSTANTS = {
    selectors: {
        titleBarClass: 'me-title-bar',
        titleClass: 'me-title',
        ctaBtnClass: 'me-cta-btn',
        fsBtnClass: 'me-fs-btn',
        headerMeFsClass: 'me-fullscreen',
        headerClass: 'finder-syndicated-header-wrapper',
    },
    configOverrides: {
        video: 'configOverrides.config.video',
        thumbnails: 'configOverrides.config.thumbnails',
        slides: 'configOverrides.config.slides'
    },
    defaultTheme: 'snowball-hover-thumbs',
    desktop: 'desktop',
    mobileWeb: 'mobileWeb',
    mediaSelectorAllTabName: 'finder.details.atAGlance.mediaEngine.all'
};

export const DESTINATION_ID_CONSTANTS = {
    wdw: '80007798;entityType=destination',
    dlr: '80008297;entityType=destination',
    hkdl: 'hkdl;entityType=destination'
};

export const DESTINATION_NAME_CONSTANTS = {
    wdw: 'Walt Disney World',
    dlr: 'Disneyland Resort',
    hkdl: 'Hong Kong Disneyland Resort'
};

export const MEAL_PERIOD_CONSTANTS = {
    mealPeriodsOrder: [
        'breakfast',
        'brunch',
        'lunch',
        'dinner',
        'lounge',
        'late-night',
    ],
    /**
     * This meal period is separate because we only want it to show in AAG and not as a
     * bookable meal period. This meal period has operating hours and appears bookable
     * but it's not. If you use it to search for a reservation it will return "nothing available".
     */
    lateNightDining: 'late-night-dining',
};

export const LOCALES = {
    en_US: 'en_US', // United States
    en_US_normalized: 'en-us',
    'es-us': 'es-us', // Spanish in US
    en_CA: 'en_CA', // Canadian in US
    en_CA_normalized: 'en-ca', // Canada normalized
    fr_ca: 'fr-ca', // French Canadian
    es_ar: 'es-ar', // Argentina
    es_mx: 'es-mx', // Mexico
    en_eu: 'en-eu', // Europe
    en_GB: 'en_GB', // UK
    en_GB_normalized: 'en-gb', // UK normalized
    en_IE: 'en_IE', // Ireland
    jp: 'jp',       // Japan
    es_pe: 'es-pe', // Peru
    es_co: 'es-co', // Colombia
    es_cl: 'es-cl', // Chile
    pt_br: 'pt-br', // Brasil
    'zh-hk': 'zh-hk', // traditional Chinese
    'zh-cn': 'zh-cn', // simplified Chinese
    ja: 'ja',
    ko: 'ko',         // korean
    th: 'th',
    id: 'id',         // Bahasa Indonesia
    ms: 'ms',         // Bahasa Malaysia
};

export const SITE_ID = {
    dlr: 'dlr',
    dcl: 'dcl',
    hkdl: 'hkdl',
    wdw: 'wdw'
};

/**
 * The purpose of this object is just to match every normalized locale (xx-xx)
 * to each locale module in @angular/common/locales folder (xx-XX)
 *
 * Magic comment webpack include should be updated with this object
 */
export const COMMON_LOCALES_MAPPING = {
    // LACD locales
    [LOCALES.es_ar]: 'es-AR',
    [LOCALES.es_cl]: 'es-CL',
    [LOCALES.es_co]: 'es-CO',
    [LOCALES.es_mx]: 'es-MX',
    [LOCALES.es_pe]: 'es-PE',
    /**
     * Since Angular 5 pt-BR is default of pt:
     * https://github.com/angular/angular/issues/20197#issuecomment-341961683
     */
    [LOCALES.pt_br]: 'pt',
    // Other countries' locales
    [LOCALES.en_CA_normalized]: 'en-CA',
    [LOCALES.fr_ca]: 'fr-CA',
    [LOCALES.en_GB_normalized]: 'en-GB',
    [LOCALES.en_eu]: 'en-150',
    /**
     * Same as pt-BR for en-US:
     * https://github.com/angular/angular/issues/28794#issuecomment-464649919
     */
    [LOCALES.en_US_normalized]: 'en',
    [LOCALES['es-us']]: 'es-US',
    // HKDL locales
    [LOCALES['zh-hk']]: 'zh-Hant',
    [LOCALES['zh-cn']]: 'zh-Hans',
    [LOCALES.ja]: 'ja',
    [LOCALES.ko]: 'ko',
    [LOCALES.th]: 'th',
    [LOCALES.id]: 'id',
    [LOCALES.ms]: 'ms',
};

export const LOCALE_DEFAULT = environment['LOCALE_DEFAULT'] || LOCALES.en_US;
export const LOCALE_NORMALIZED_DEFAULT = environment['LOCALE_NORMALIZED_DEFAULT'] || LOCALES.en_US_normalized;
export const LOCALE_HREF_PREFIX_IGNORELIST = [
    LOCALES.en_US_normalized,
    LOCALES.en_GB_normalized,
    LOCALES.en_eu
];

/**
 * Regexp used to match the LACD locales.
 * Matches: es-us, en_GB, pt-br, jp, en and non-standard locales like EN_US, Fr-CA, etc.
 * Does not match: esUS
 *
 * If you update this regex you should update this
 * src/static/server.ts: getLocale
 */
export const LOCALE_REGEXP = {
    lacd: /^[a-zA-Z]{2}((_|-)[a-zA-Z]{2})?$/g,
};

export const FINDER_TOGGLES_COOKIE_NAME = '__ft';

export const MOBILE_MEDIA_QUERY = '(max-width: 768px)';

export const CAMPAIGN_CONSTANTS = {
    isCampaign: 'isCampaign',
    isOverview: 'isOverview',
    themeKey: 'themeData',
};

export const APP_NAME_HEADER = {
    name: 'X-Disney-App-Name',
    value: 'advanced-finder'
};

export const SEO_OPTIONS: SeoOptionsInterface = {
    useTranslate: false,
    serverPath: '/seo',
    manualBootstrap: false,
    includeMetaTag: {
        alternate: false,
    },
    customSeoRetrieval: true,
};

export const TRAVEL_AGENT = {
    cookieName: 'SHARED_SESSION_LINK',
    prefix: 'reservations',
    store: 'dta-packages-std',
    caStore: 'dta-packages-std-ca',
    wdwTicketsStore: 'travel-agents',
    dlrTicketsStore: 'agent',
    storeCast: 'cast-resorts',
    storeTaap: 'dta-packages-taap',
    caStoreTaap: 'dta-packages-taap-ca',
    header: 'x-disney-travel-agent-store'
};

export const CALENDAR_CONSTANTS = {
    sessionStorage: {
        selectedDate: 'calendarSelectedDate',
        selectedFilters: 'calendarSelectedFilters'
    },
    parkCalendarsPath: 'park-calendars',
    calendarsDefaultPath: 'calendars',
    // These HKDL locales are those that allow Magic Access Blockout date info to be shown
    hkdlMagicAccessLocales: ['zh-cn', 'zh-hk', 'en', 'en-us']
};

export const ANALYTICS_TIMEOUT = 2000;

export const ANALYTICS_FACETS_UTILIZED_ACTION = {
    add: 'Add',
    remove: 'Remove'
};

export const ANALYTICS_PAGE_KEY_HEADER_NAME = 'x-disney-analytics-page-key';

export const ANALYTICS_DEFAULT_SITE_SECTION = '/tools/finder';

export const ANALYTICS_CUSTOM_PAGE_ID_LIST = [
    '#',
    '/calendars',
    '/park-calendars'
];

export const ANALYTICS_CONSTANTS = {
    config: {
        serverPath: 'analytics',
        pageViewTrackTiming: 2500,
    },
    errorPage: {
        siteSection: 'content/error',
    },
    geo: {
        CA: {
            name: 'california',
            resident: 'California Resident',
        },
        FL: {
            name: 'florida',
            resident: 'Florida Resident',
        },
        affiliations: {
            SoCal: {
                zipStart: 90000,
                zipEnd: 93599,
                name: 'SoCal',
            },
        },
    },
    pageVariant: 'advfinder',
    siteSectionPrefix: 'tools/finder',
    storeType: 'consumer',
    availDetailSearchButtonLinkId: 'DineDetails_SearchTimes',
    availDetailSearchSuccess: 'DineCheckAvail_Success',
};

export const THEMES = {
    AC: 'ac',
    Aulani: 'aulani',
    CC: 'cc',
    Genie: 'genie',
    GotG: 'gotg',
    GSC: 'gsc',
    Live: 'live',
    Snowball: 'snowball',
    SWGE: 'swge',
    WDW50: 'wdw50',
    MBP: 'mbp',
    Hyperion: 'hyperion',
    EVE: 'eve',
};

export const MODAL_DEEP_LINKS = {
    discounts: 'discounts-modal',
    atmp: 'my-plans-modal',
    checkAvailability: 'availability-modal',
};

export const HEADERS_DEEP_LINKS = {
    packageCards: 'featured-products'
};

export const LIST_CARD_ANCHOR_AS_EXTERNAL = [
    // wdw
    'enchanting-extras-collection',
    // hkdl
    'mobile-app',
    'disney-priority-special'
];

export const LODGING_PATHS = {
    dlr: {
        routes: ['hotels', 'gnh-hotels'],
        subPath: 'good-neighbor',
        explorerEntityName: 'gnh-hotels'
    },
    wdw: {
        routes: ['resorts', 'mga-resorts'],
        subPath: 'more',
        explorerEntityName: 'mga-resorts'
    },
    hkdl: {
        routes: ['hotels'],
        subPath: '',
        explorerEntityName: 'hotels'
    }
};

export const REMY_CONSTANTS = {
    config: 'remyConfig',
    enabled: 'enabled',
    endpoints: 'endpoints',
    // Add slug-id to the end
    restaurant: '/restaurant',
    search: '/availability'
};

export const USER_AGENT_OS = {
    android: 'Android',
    ios: 'iOS',
    linux: 'Linux',
    macos: 'macOS',
    unknown: 'Unknown OS',
    windows: 'Windows'
};
